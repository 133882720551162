/* eslint-disable import/prefer-default-export */
/* global NoCaptcha */
import { log } from 'video.js';
import { captchaAppKey, captchaScene } from './index'

export function create(elementId, callback) {
  const ncToken = [captchaAppKey, new Date().getTime(), Math.random()].join(':')
  const scene = captchaScene

  const nc = NoCaptcha.init({
    // renderTo: '#nc',
    renderTo: `#${elementId}`,
    appkey: captchaAppKey,
    scene,
    token: ncToken,
    trans: { key1: 'code200' },
    elementID: ['usernameID'],
    is_Opt: 0,
    language: 'cn',
    timeout: 10000,
    retryTimes: 5,
    errorTimes: 5,
    inline: false,
    bannerHidden: false,
    initHidden: true,
    callback(data) {
      const params = {
        sessionId: data.csessionid,
        sign: data.sig,
        token: ncToken,
        scene
      }
      //成功后追加重置滑块按钮
      callback(params)
    },
    error:(e)=> {
      nc.reset()
    },
  })
  NoCaptcha.setEnabled(true)
  nc.reset() // 请务必确保这里调用一次reset()方法
  NoCaptcha.upLang('cn', {
    LOADING: '加载中...', // 加载
    SLIDER_LABEL: '请向右滑动验证', // 等待滑动
    CHECK_Y: '验证通过', // 通过
    ERROR: "验证失败，点击框体重新验证",
    FAIL: "验证失败，点击框体重新验证",
    ERROR_TITLE: '验证失败，点击框体重新验证', // 拦截
    CHECK_N: '验证失败，点击框体重新验证', // 准备唤醒二次验证
    OVERLAY_INFORM: '经检测你当前操作环境存在风险，请输入验证码', // 二次验证
    TIPS_TITLE: '验证码错误，请重新输入' // 验证码输错时的提示
  })
  return nc
}
